<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { cva } from "class-variance-authority";
import { type ButtonProps } from "./protocols";

withDefaults(defineProps<ButtonProps>(), {
  variant: "contained",
  disabled: false,
});

const buttonVariances = cva("button", {
  variants: {
    variant: {
      contained: "contained",
      text: "text",
      outlined: "outlined",
    },
  },
});
</script>
<template>
  <button
    :class="{
      [buttonVariances({ variant })]: true,
      disabled: $props.disabled,
    }"
    :disabled="$props.disabled"
  >
    <template v-if="loading">
      <Icon icon="gg:spinner" class="animate-spin" width="20" height="20" />
    </template>

    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>
<style lang="css" scoped>
.button {
  @apply rounded-lg py-[10px] px-5 text-black font-semibold cursor-pointer  flex items-center justify-center text-sm uppercase min-h-[40px];
}

.contained {
  @apply relative  bg-primary-400 hover:bg-primary-700 border-none;
}

.text {
  @apply bg-transparent hover:bg-primary-400/25 border-none text-primary-400;
}

.outlined {
  @apply bg-transparent border-solid border-2 border-primary-400 text-primary-400 hover:bg-primary-400 hover:text-black;
}

.disabled,
.disabled:hover {
  @apply bg-gray-chateau cursor-default;
}
</style>
